import { Link, withRouter } from 'react-router-dom';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import WupDropdown from './../generic/wup_dropdown';
import CartIcon from './../icons/cart_icon';
import UploadIcon from './../icons/upload_icon';
import StorageIcon from './../icons/storage_icon';


const AccountMenu = (props) => {

  const logoutAndRedirect = () => {
    props.logout().then(() => window.location = '/users/sign_in');
  }

  return (
    <WupDropdown
      className='account-menu-dropdown'
      label={<AccountCircleOutlinedIcon className='control-icon' />}
      dropdown={
        <>
          <Link to={`/`}>Home</Link>
          <Link to={`/upload`}>Upload</Link>
          <Link to={`/settings/account`}>Account</Link>
          <Link to={`/dashboard/past-orders`}>Orders</Link>
          <Link to={'/dashboard/sales-reports'}>Sales History</Link>
          <a onClick={logoutAndRedirect}>Logout</a>
        </>
      }
    />
  );
}

const Controls = (props) => {
  const {
    cart,
    currentUser,
    logout,
  } = props;

  const displayUserOrLogIn = currentUser ?
    <AccountMenu logout={logout} /> :
    (<a className='controls-text-link' href='/users/sign_in'>Login</a>);

  const displaySignUp = currentUser ? null : (<a className='controls-text-link' href='/users/sign_up'>Sign Up</a>);

  let cartCount;
  if(_.isEmpty(cart.lineItems)) {
    cartCount = null;
  } else {
    cartCount = <span className='cart-count'>{cart.lineItems.length}</span>;
  }

  return (
    <div className='controls'>
      {currentUser ?
        <>
          <Link className='upload-control' to='/upload'>
            <UploadIcon className="upload-icon" />
            Upload
          </Link>
          <Link className='upload-control' to='/dashboard/uploads'>
            <StorageIcon className="storage-icon" />
            My Uploads
          </Link>
          <div className='control-divider'></div>
        </> : null}
      <Link className='cart-link upload-control' to='/cart'>
        {cartCount}
        <CartIcon className="cart-icon" />
      </Link>
      {displaySignUp}
      {displayUserOrLogIn}
    </div>
  );
}

export default withRouter(Controls);
